/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { fetchData, } from '../../../../reusable'
import 'react-multi-carousel/lib/styles.css'
import '../../../../_metronic/assets/style/style.css'
import { stateFromHTML } from 'draft-js-import-html'
import { EditorState } from 'draft-js'
import { LayOut } from '../Home'

export function AboutUsContent() {

    useEffect(() => {
        document.title = 'Inalts About Us';
        loginapicall()
    }, []);


    const [projects, setprojects] = useState([])

    const convertHtmlToEditorState = (html: any) => {
        const blocksFromHTML = stateFromHTML(html);

        return EditorState.createWithContent(blocksFromHTML);
    };


    const loginapicall = async () => {
        var param: any = {
            contentId: '66dbe663b4e0ae7bb0a99b6b'
        }

        var response: any = await fetchData('contentList', param)

        if (response.status == "200") {
            console.log(response.data)
            setprojects(response.data)

        }
        else {
            console.log(response)
        }

    }


    return (
        <>
            <div className='mt-5'>

                {projects.map((obj: any) => {

                    const description = obj.description;

                    let displayText;
                    const editorState = convertHtmlToEditorState(description);
                    // displayText = editorStateToPlainText(editorState);
                    return (
                        <div className='mt-20 pt-5 mb-5'>
                            <div className='col-12 text-center line-under-image-text my-6' style={{lineHeight:0.6}}>
                                <div className='m-0'>{obj.title}</div>
                                <img src={toAbsoluteUrl('/media/homeImg/Group.svg')} width={'176px'} />
                            </div>
                            {/* <h1 className='my-5 text-center pt-5'>{obj.title}</h1> */}
                            <div className='row justify-content-center mx-0 '>
                                <div className='col-lg-8 align-items-center pt-4'>

                                    <div dangerouslySetInnerHTML={{ __html: obj.description }} style={{
                                        // fontSize: '16px',
                                        // textAlign: 'justify',
                                        // fontFamily: '',
                                        color: "grey"
                                    }}></div>

                                </div>
                            </div>
                        </div>
                    )
                }
                )
                }
            </div>
        </>
    )
}

export default function AboutUs() {
    return <LayOut data={<AboutUsContent />} ></LayOut>;
}